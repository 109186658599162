#location-incompatible-device-text,
#location-prompt-text {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

#location-incompatible-device-text.visible,
#location-prompt-text.visible {
  opacity: 1;
}

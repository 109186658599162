#color-puzzle-box {
  display: grid;
  grid-template: repeat(2, auto) / repeat(6, 1fr);
  row-gap: 40px;
  column-gap: 10px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: calc(80px * 6);
}

#color-puzzle-box.error-flash {
  animation: flashError 0.8s ease-in-out;
}

#color-error-overlay {
  border-radius: 5px;
  color: white;
  @apply bg-red-700/85;
  animation: fadeInOut 2s;
}

#color-puzzle-grid,
#color-emoji-header {
  opacity: 0;
  transition: opacity ease-in-out 0.6s;
}

#form-success-header {
  opacity: 0;
  transition: opacity ease-out 0.3s;
}

#color-puzzle-grid.visible,
#form-success-header.visible,
#color-emoji-header.visible {
  opacity: 1;
}

.color-rectangle {
  aspect-ratio: 1 / 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  max-width: 80px;
}

.color-rectangle.untapped {
  animation: untappedButton 1.5s ease-in-out infinite;
}

#contact-submission-form {
  opacity: 0;
  animation: show ease-in-out 1s forwards;
}

@keyframes untappedButton {
  0% {
    @apply bg-slate-400;
  }
  50% {
    @apply bg-slate-500;
  }
  100% {
    @apply bg-slate-400;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

p {
  color: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background: rgba(229, 227, 57, 1);
}

body {
  width: 100%;
  font-family:
    "SF Pro",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    to bottom,
    rgba(229, 227, 57, 0.85) 0%,
    rgba(202, 102, 54, 1) 50%,
    rgba(229, 227, 57, 0.85) 100%
  );
}

#root {
  @apply size-full;
}

.page-container {
  @apply size-full gap-4 flex flex-col items-center p-4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* BUTTONS */
.directive {
  @apply text-yellow-100 opacity-0 italic font-bold;
  transition: opacity ease-in 1.2s;
}

.directive.showing {
  opacity: 1;
}

.btn {
  @apply font-bold py-3 px-4 rounded;
}

.btn.blinking {
  animation: blinking 0.5s ease-in-out infinite alternate;
  animation-iteration-count: infinite;
}

.btn-blue {
  @apply bg-blue-700 text-white;
}

.btn-blue:hover {
  @apply bg-blue-900;
}

.btn-blue:disabled {
  @apply bg-blue-400;
}

@keyframes blinking {
  from {
    @apply bg-blue-500;
  }
  to {
    @apply bg-blue-700;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image-shader {
  position: absolute;
}

.image-shader::before {
  content: "";
  inset: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.fade-in {
  animation: fadeIn ease-in-out 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

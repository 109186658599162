.puzzle-location-image {
  transition: all;
  overflow: hidden;
}

.puzzle-location-image.animating {
  animation: blurScale ease-in 1s;
}

@keyframes blurScale {
  0% {
    transform: scale(1);
    filter: brightness(0) blur(0);
  }
  40% {
    transform: scale(1.03);
    filter: brightness(2.5) blur(10px);
  }

  60% {
    transform: scale(1.06);
    filter: brightness(3.5) blur(15px);
  }
  100% {
    transform: scale(1);
    filter: brightness(1) blur(0);
  }
}
